



























































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';
import Embeddable from '@/components/Common/Embeddable.vue';
import { EmbeddedContent, EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';

@Component({
  components: {
    Form,
    Embeddable,
  },
})
export default class WhyRentWithUs extends Mixins(View) {
  private eb: EmbeddedContent = {
    Type: EmbeddedContentType.Youtube,
    EmbedId: 'PUmQSgs3xo4',
  };
}
